<template>
  <div>
    <v-card>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-card-title class="d-flex justify-space-between flex-wrap">
          <div>{{ $t('companies.create') }}</div>

          <div class="d-flex align-center flex-wrap">
            <v-btn
              color="success"
              dark
              class="mr-2"
              @click.prevent="save"
            >
              {{ $t('general.save') }}
            </v-btn>

            <router-link :to="{name:'admin-company-list'}">
              <v-btn
                dark
                outlined
                color="error"
                class="mr-2"
              >
                {{ $t('general.close') }}
              </v-btn>
            </router-link>
          </div>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="company.name"
                dense
                outlined
                :label="$t('companies.name')"
                class="w-full"
                :rules="[validator.required]"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-text-field
                v-model="company.address"
                dense
                outlined
                :label="$t('companies.address')"
                class="w-full"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-text-field
                v-model="company.addressAdditional"
                dense
                outlined
                :label="$t('companies.addressAdditional')"
                class="w-full"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-text-field
                v-model="company.zipCode"
                dense
                outlined
                :label="$t('companies.zipCode')"
                class="w-full"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-text-field
                v-model="company.city"
                dense
                outlined
                :label="$t('companies.city')"
                class="w-full"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-text-field
                v-model="company.state"
                dense
                outlined
                :label="$t('companies.state')"
                class="w-full"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-text-field
                v-model="company.country"
                dense
                outlined
                :label="$t('companies.country')"
                class="w-full"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-text-field
                v-model="company.email"
                dense
                outlined
                :label="$t('companies.email')"
                class="w-full"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-text-field
                v-model="company.phone"
                dense
                outlined
                :label="$t('companies.phone')"
                class="w-full"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-text-field
                v-model="company.companyIdentificationNumber"
                dense
                outlined
                :label="$t('companies.companyIdentificationNumber')"
                class="w-full"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-text-field
                v-model="company.companyIdentificationType"
                dense
                outlined
                :label="$t('companies.companyIdentificationType')"
                class="w-full"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-select
                v-model="company.status"
                dense
                outlined
                :items="statusOptions"
                item-value="key"
                :item-text="item => $t(item.name)"
                :label="$t('general.status')"
                class="w-full"
              >
              </v-select>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-select
                v-model="company.companyType"
                dense
                outlined
                :items="$store.state.companyTypes"
                item-value="key"
                :item-text="item => $t(item.name)"
                :label="$t('general.type')"
                class="w-full"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="success"
            dark
            class="mr-2"
            @click.prevent="save"
          >
            {{ $t('general.save') }}
          </v-btn>

          <router-link :to="{name:'admin-company-list'}">
            <v-btn
              dark
              outlined
              color="error"
              class="mr-2"
            >
              {{ $t('general.close') }}
            </v-btn>
          </router-link>
        </v-card-actions>
      </v-form>
    </v-card>

    <v-snackbar
      v-model="snackbar"
    >
      {{ $t('companies.dataSaved') }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('general.close' ) }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiPlus,
  mdiSquareEditOutline,
} from '@mdi/js'
import { required } from '@core/utils/validation'
import axiosIns from '@axios'

export default {
  components: {},
  data() {
    const companyType = null
    const companyId = null
    const snackbar = false
    const totalCompanies = 0
    const loading = true
    const options = {}
    const company = {
      name: null,
      email: null,
      phone: null,
      address: null,
      addressAdditional: null,
      zipCode: null,
      city: null,
      state: null,
      country: null,
      companyIdentificationNumber: null,
      companyIdentificationType: null,
      status: 'pending',
    }

    const statusFilter = ''

    const statusOptions = this.$store.state.baseStatuses

    return {
      companyType,
      companyId,
      snackbar,
      options,
      loading,
      statusFilter,
      company,
      totalCompanies,
      statusOptions,
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
      },
      validator: {
        required,
      },
    }
  },

  methods: {
    save() {
      if (this.$refs.form.validate()) {
        const company = {
          name: this.company.name,
          email: this.company.email,
          phone: this.company.phone,
          address: this.company.address,
          address_additional: this.company.addressAdditional,
          zip_code: this.company.zipCode,
          city: this.company.city,
          state: this.company.state,
          country: this.company.country,
          company_identification_number: this.company.companyIdentificationNumber,
          company_identification_type: this.company.companyIdentificationType,
          status: this.company.status,
          company_type: this.company.companyType,
        }

        axiosIns({
          method: this.companyId ? 'PUT' : 'POST',
          data: company,
          url: this.companyId ? `/admin/companies/${this.companyId}` : '/admin/companies',
        })
          .then(res => {
            this.snackbar = true
            this.companyId = res.data.company.id
          })
          .catch()
      }
    },
  },
}
</script>

<style lang="scss">
</style>
